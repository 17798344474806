// @ts-ignore
import { EventResponse, EventResponseItem } from '@/types/response'

export default class LoggableEvent {
  private constructor (
    readonly id: string,
    readonly createdAt: string | null,
    readonly name: string | null,
    readonly icon: string | null,
    readonly kind:string
  ) {}

  public static createEmpty(): LoggableEvent {
    return new LoggableEvent(
      '00000000-0000-0000-0000-000000000000',
     null,
      null,
      null,
      '')
  }

  public static createFromResponse(response: EventResponse): LoggableEvent[] {
    return response.map((item: EventResponseItem) => {
      return new LoggableEvent(
        item.id,
        item.createdAt,
        item.name,
        item.icon,
        item.kind
      )
    })
  }

  public  static createEventFromResponse(response: EventResponseItem): LoggableEvent {
    return new LoggableEvent(
      response.id,
      response.createdAt,
      response.name,
      response.icon,
      response.kind
    )
  }

  get isEventNew(): boolean {
    return this.id === '00000000-0000-0000-0000-000000000000'
  }
}