import { MonthStatisticDetailResponse } from '@/types/response'
import moment from "moment"
import MonthStatisticDateItem from '@/model/MonthStatisticDateItem'

export default class MonthStatisticDetail {
  private constructor (
    readonly id: string,
    readonly personId: string,
    readonly firstName: string,
    readonly familyName: string,
    readonly totalWorkedTime: string,
    readonly hasConflicts: boolean,
    readonly month: string,
    readonly dateItems: MonthStatisticDateItem[],
  ) {}

  get fullName (): string {
    return `${this.firstName} ${this.familyName}`
  }

  public static createFromResponse (response: MonthStatisticDetailResponse): MonthStatisticDetail {
    const dateItems: MonthStatisticDateItem[] = MonthStatisticDateItem.createFromResponse(response.dateItems)
    let minutes: number = moment.duration(parseInt(response.totalWorkedTime)*1000).minutes()
    let hours: number = Math.trunc(moment.duration(parseInt(response.totalWorkedTime)*1000).asHours())
    return new MonthStatisticDetail(
       response.id,
       response.person.id,
       response.person.firstName,
       response.person.familyName,
      minutes ? `${hours}h ${minutes}m` : `${hours}h`,
       response.hasConflicts,
       response.month,
       dateItems
      )
  }
}



