import { UserResponse } from '../types/response'

export default class CurrentUser {
  private constructor (
    readonly id: string,
    readonly firstName: string,
    readonly familyName: string
  ) {}

  get fullName (): string {
    return `${this.firstName} ${this.familyName}`
  }

  public static createEmpty(): CurrentUser {
    return new CurrentUser('00000000-0000-0000-0000-000000000000', '', '')
  }

  public static createFromResponse(response: UserResponse): CurrentUser {
    return new CurrentUser(
      response.id,
      response.firstName,
      response.familyName
    )
  }
}
