
















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Language from '@/model/Language'
import { CURRENT_UI_LANGUAGE, SUPPORTED_LANGUAGES } from '@/store/getter-types'
import { SWITCH_APPLICATION_LANGUAGE } from '@/store/action-types'

@Component
export default class LanguageSelectionButton extends Vue {
  $refs!: {
    activatorButton: Vue
  }

  @Getter(SUPPORTED_LANGUAGES) supportedLanguages!: Language[]
  @Getter(CURRENT_UI_LANGUAGE) currentLanguage!: Language

  @Action(SWITCH_APPLICATION_LANGUAGE) switchApplicationLanguage!: { (payload: Language): void }

  get activator (): Vue {
    return this.$refs.activatorButton
  }

  onLanguageSelect (language: Language): void {
    this.switchApplicationLanguage(language)
  }
}
