//<editor-fold desc="Global actions">
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER'
export const SET_DRAWER_STATE = 'SET_DRAWER_STATE'
export const SWITCH_APPLICATION_LANGUAGE = 'SWITCH_APPLICATION_LANGUAGE'
export const INIT_STORE = 'INIT_STORE'

export const FETCH_TOKEN = 'FETCH_TOKEN'
//</editor-fold>

//<editor-fold desc="Auth module actions">
export const AUTH_INIT_MODULE = 'AUTH_INIT_MODULE'
export const AUTH_LOG_USER_IN = 'AUTH_LOG_USER_IN'
export const AUTH_LOG_USER_OUT = 'AUTH_LOG_USER_OUT'
export const AUTH_LOG_USER_IN_WITH_TOKEN = 'AUTH_LOG_USER_IN_WITH_TOKEN'
export const SET_INVALID_TOKEN = 'SET_INVALID_TOKEN'
//</editor-fold>

export const FETCH_EVENTS = 'FETCH_EVENTS'

export const FETCH_MONTH_STATISTIC_BY_ID= 'FETCH_MONTH_STATISTIC_BY_ID'
export const FETCH_DATE_STATISTIC_BY_ID= 'FETCH_DATE_STATISTIC_BY_ID'
