import axios from 'axios'
import { AUTH_LOG_USER_OUT, SET_INVALID_TOKEN } from '@/store/action-types'
import store from '@/store'
import auth from '@/store/modules/auth'

function addDefaultHeadersInterceptor (config: any): any {
  config.headers.get['Accept'] = 'application/json'

  return config
}

const config = {
  baseURL: process.env.VUE_APP_API_ROOT
}

const instance = axios.create(config)

instance.interceptors.request.use(addDefaultHeadersInterceptor)

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    if (auth.state.currentUser) {
      store.dispatch(SET_INVALID_TOKEN,true)
    }
    store.dispatch(AUTH_LOG_USER_OUT)
  }
  return Promise.reject(error)
})

export default instance
