






























import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import MonthPicker from '../../../shared/components/MonthPicker.vue'
import {MonthStatisticDetailPayload} from 'app/src/types/payload'
import {Action, Getter} from 'vuex-class'
import {FETCH_MONTH_STATISTIC_BY_ID} from 'app/src/store/action-types'
import MonthStatisticDetail from 'app/src/model/MonthStatisticDetail'
import {CURRENT_USER} from '@/store/getter-types'
import CurrentUser from '../../../shared/model/CurrentUser'
import MonthStatisticDateItem from "cp/src/model/MonthStatisticDateItem";
import PersonDetailDialog from "@/components/PersonDetailDialog.vue";

@Component({
  components: {
    MonthPicker,
    PersonDetailDialog
  }
})
export default class OverviewOfPerson extends Vue {

  @Getter(CURRENT_USER) currentUser!: CurrentUser
  @Action(FETCH_MONTH_STATISTIC_BY_ID) fetchMonthStatisticsDetail!: { (payload: MonthStatisticDetailPayload): Promise<MonthStatisticDetail> }

  private fetchInProgress: boolean = false
  private selectedMonth: string = ''
  private monthStatisticsDetailData: MonthStatisticDetail | null = null
  private selectedDetail: MonthStatisticDateItem | null = null
  private showPersonDetailDialog: boolean = false

  private errorMessage: string = ''
  snackbar: boolean = false

  get showNoData(): boolean {
    return !this.monthStatisticsDetailData || this.monthStatisticsDetailData.dateItems.length === 0
  }

  onCloseDetailDialog() {
    this.showPersonDetailDialog = false
  }

  onPersonDetailDataClick(detail: MonthStatisticDateItem) {
    this.selectedDetail = detail
    this.showPersonDetailDialog = true

  }

  getClass(item: MonthStatisticDetail): string {
    if (item.hasConflicts) {
      return 'error'
    } else {
      return ''
    }
  }

  onMonthChange(month: string): void {
    this.selectedMonth = month
    this.fetchData()
  }

  async fetchData(): Promise<void> {
    const payload: MonthStatisticDetailPayload = {
      id: this.currentUser.id,
      month: this.selectedMonth
    }
    try {
      this.fetchInProgress = true
      this.monthStatisticsDetailData = await this.fetchMonthStatisticsDetail(payload)
    } catch (e) {
      this.errorMessage = this.$t('errors.fetchDataError').toString()
      this.snackbar = true
    } finally {
      this.fetchInProgress = false
    }
  }

  mounted(): void {
    this.fetchData()
  }

}
