


























import {Component} from 'vue-property-decorator'
import Vue from 'vue'
import CheckDialog from '@/components/CheckDialog.vue'
import {Action} from 'vuex-class'
import {FETCH_EVENTS} from 'cp/src/store/action-types'
import LoggableEvent from '../../../shared/model/LoggableEvent'

@Component({
  components: {
    CheckDialog
  }
})
export default class CheckPage extends Vue {

  @Action(FETCH_EVENTS) fetchData!: { (): Promise<LoggableEvent[]> }

  private showDialog: boolean = false
  private loggableEvent: LoggableEvent | null = null
  private buttonList: LoggableEvent[] | null = null
  private showSnackbar: boolean = false
  private errorMessage: string = ''
  private fetchInProgress: boolean = false

  getClass(): string {
    if ((this.buttonList && this.buttonList.length === 2 && this.$vuetify.breakpoint.xsOnly) || (!this.buttonList && this.$vuetify.breakpoint.xsOnly)) {
      return 'grid-container'
    } else if (!this.buttonList && !this.fetchInProgress) {
      return 'error-text'
    } else {
      return 'grid-container-bigger'
    }
  }

  onButtonClick(item: LoggableEvent): void {
    this.loggableEvent = item
    this.showDialog = true
  }

  onCloseDialog(): void {
    this.showDialog = false
    this.loggableEvent = null
  }


  async fetchEventList(): Promise<void> {
    try {
      this.fetchInProgress = true
      this.buttonList = await this.fetchData()
    } catch (e) {
      this.errorMessage = this.$t('errors.fetchDataError').toString()
      this.showSnackbar = true
    } finally {
      this.fetchInProgress = false
    }
  }

  mounted(): void {
    this.fetchEventList()
  }

}
