import Vue from 'vue'
import Vuex, { Store, StoreOptions } from 'vuex'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import { AuthState, GlobalState } from '@/types/state'
import * as getterTypes from '@/store/getter-types'
import * as mutationTypes from '@/store/mutation-types'
import * as actionTypes from '@/store/action-types'
import authModule from '@/store/modules/auth'
import Language from '@/model/Language'
import { CURRENT_LANGUAGE_FIELD } from '@/constants'
import LoggableEvent from 'cp/src/model/LoggableEvent'
import { AxiosHeaders } from 'app/src/types'
import { TOKEN_FIELD } from 'app/src/constants'
import { AxiosResponse } from 'axios'
import {EventTokenPayload, MonthStatisticDetailPayload} from "@/types/payload";
import EventToken from "@/model/EventToken";
import axios from 'app/src/plugins/axios'
import MonthStatisticDetail from "@/model/MonthStatisticDetail";
import {DateStatisticDetailPayload} from "cp/src/types/payload";
import MonthStatisticDateItem from "@/model/MonthStatisticDateItem";

Vue.use(Vuex)

const supportedLanguages: Language[] = [
  Language.create('sk', i18n.t('languages.slovakLanguageLabel').toString(), false),
  Language.create('en', i18n.t('languages.englishLanguageLabel').toString(), false),
]

const state = (): GlobalState => ({
  auth: {} as AuthState,
  showAppDrawer: undefined,
  appUpdateAvailable: false,
  supportedLanguages,
  currentUiLanguage: supportedLanguages[0]
})

const storeOptions: StoreOptions<any> = {
  modules: {
    auth: authModule
  },

  state,

  getters: {
    [getterTypes.APP_DRAWER_STATE] (state: GlobalState): boolean {
      return !!state.showAppDrawer
    },

    [getterTypes.IS_APP_UPDATE_AVAILABLE] (state: GlobalState): boolean {
      return state.appUpdateAvailable
    },

    [getterTypes.SUPPORTED_LANGUAGES] (state: GlobalState): Language[] {
      return state.supportedLanguages
    },

    [getterTypes.CURRENT_UI_LANGUAGE] (state: GlobalState): Language {
      return state.currentUiLanguage
    },
  },

  mutations: {
    [mutationTypes.TOGGLE_APP_DRAWER_MUTATION] (state: GlobalState): void {
      state.showAppDrawer = !state.showAppDrawer
    },

    [mutationTypes.SET_DRAWER_STATE_MUTATION] (state: GlobalState, payload: boolean): void {
      state.showAppDrawer = payload
    },

    [mutationTypes.APP_UPDATE_IS_AVAILABLE] (state: GlobalState): void {
      state.appUpdateAvailable = true
    },

    [mutationTypes.APP_UPDATE_IS_AVAILABLE] (state: GlobalState): void {
      state.appUpdateAvailable = true
    },

    [mutationTypes.SET_CURRENT_UI_LANGUAGE] (state: GlobalState, payload: Language): void {
      state.currentUiLanguage = payload
      localStorage.setItem(CURRENT_LANGUAGE_FIELD, payload.code)
    },
  },

  actions: {
    async [actionTypes.INIT_STORE] ({ commit, dispatch }: { commit: Function, dispatch: Function }): Promise<void> {
      const storedLanguageCode: string | null = localStorage.getItem(CURRENT_LANGUAGE_FIELD)

      if (storedLanguageCode) {
        const selectedLanguage: Language | undefined  = (this.getters[getterTypes.SUPPORTED_LANGUAGES] as Language[]).find(
          (l: Language) => l.code === storedLanguageCode
        )

        if (selectedLanguage) {
          commit(mutationTypes.SET_CURRENT_UI_LANGUAGE, selectedLanguage)
        }
      }

      await Promise.all([
        dispatch(actionTypes.AUTH_INIT_MODULE),
      ])
    },

    [actionTypes.TOGGLE_APP_DRAWER] ({ commit }: { commit: Function }): void {
      commit(mutationTypes.TOGGLE_APP_DRAWER_MUTATION)
    },

    [actionTypes.SET_DRAWER_STATE] ({ commit }: { commit: Function }, payload: boolean): void {
      commit(mutationTypes.SET_DRAWER_STATE_MUTATION, payload)
    },

    [actionTypes.SWITCH_APPLICATION_LANGUAGE] ({ commit }: { commit: Function }, payload: Language): void {
      vuetify.framework.lang.current = payload.code
      vuetify.framework.rtl = payload.rtl

      i18n.locale = payload.code

      if (this.getters[getterTypes.CURRENT_UI_LANGUAGE] !== payload) {
        commit(mutationTypes.SET_CURRENT_UI_LANGUAGE, payload)
      }
    },

    async [actionTypes.FETCH_EVENTS](): Promise<LoggableEvent[]> {

      let headers: AxiosHeaders = {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_FIELD)}`
      }

      const eventListResponse: AxiosResponse = await axios.get('/loggable-items', {headers: headers})
      return LoggableEvent.createFromResponse(eventListResponse.data)
    },

    async [actionTypes.FETCH_TOKEN]({state}: { state: GlobalState }, payload: EventTokenPayload): Promise<EventToken> {

      let body = {
        loggableItemId: payload.id
      }

      let fetchEventTokenResponse: AxiosResponse = await axios.post('/loggable-token', body,{
        headers: {
          'Accept': 'application/json',
          'Authorization': `bearer ${state.auth.token}`
        }
      })

      return EventToken.createEventToken(fetchEventTokenResponse.data)
    },

    async [actionTypes.FETCH_MONTH_STATISTIC_BY_ID]({commit}: { commit: Function }, payload: MonthStatisticDetailPayload): Promise<MonthStatisticDetail> {

      let headers: AxiosHeaders = {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_FIELD)}`
      }

      const monthStatisticsIdResponse: AxiosResponse = await axios.get(`/statistics/by-month/${payload.id}`,
        {
          headers: headers,
          params: {
            month: payload.month
          }
        })
      return MonthStatisticDetail.createFromResponse(monthStatisticsIdResponse.data)
    },

    async [actionTypes.FETCH_DATE_STATISTIC_BY_ID]({commit}: { commit: Function }, payload: DateStatisticDetailPayload): Promise<MonthStatisticDateItem> {
      let headers: AxiosHeaders = {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_FIELD)}`
      }

      const dateStatisticByIdResponse: AxiosResponse = await axios.get(`/statistics/by-date/${payload.id}`,
          {
            headers: headers,
            params: {
              date: payload.date
            }
          })
      return MonthStatisticDateItem.createFromResponseItem(dateStatisticByIdResponse.data)
    },
  }
}

export default new Store(storeOptions)
