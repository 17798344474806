import {EventTokenResponse} from "@/types/response";

export default class EventToken {
    private constructor(
        readonly token: string
    ) {}

    public static createEventToken(responseEventToken: EventTokenResponse): EventToken {
        return new EventToken(
            responseEventToken.token
        )
    }
}