//<editor-fold desc="Global getters">
export const APP_DRAWER_STATE = 'APP_DRAWER_STATE'
export const SUPPORTED_LANGUAGES = 'SUPPORTED_LANGUAGES'
export const CURRENT_UI_LANGUAGE = 'CURRENT_UI_LANGUAGE'
//</editor-fold>

//<editor-fold desc="Auth module getters">
export const IS_USER_LOGGED_IN = 'IS_USER_LOGGED_IN'
export const IS_APP_UPDATE_AVAILABLE = 'IS_APP_UPDATE_AVAILABLE'
export const CURRENT_USER = 'CURRENT_USER'
export const AUTH_TOKEN = 'AUTH_TOKEN'
export const IS_INVALID_TOKEN = 'IS_INVALID_TOKEN'
//</editor-fold>
