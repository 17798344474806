






















import {Component, Prop} from 'vue-property-decorator'
import Vue from 'vue'
import UserQR from '@/components/UserQR.vue'
import {Action, Getter} from 'vuex-class'
import {CURRENT_USER} from '@/store/getter-types'
import CurrentUser from '../../../shared/model/CurrentUser'
import {EventTokenPayload} from "@/types/payload";
import {FETCH_TOKEN} from "@/store/action-types";
import EventToken from "@/model/EventToken";
import LoggableEvent from "../../../shared/model/LoggableEvent";

@Component({
  components: {
    UserQR
  }
})
export default class CheckDialog extends Vue {

  @Action(FETCH_TOKEN) fetchToken!: { (payload: EventTokenPayload): Promise<EventToken> }

  @Getter(CURRENT_USER) currentUser!: CurrentUser

  @Prop({type: Boolean, default: false}) readonly show!: boolean
  @Prop({type: Object, required: true}) readonly loggableEvent!: LoggableEvent

  private showSnackbar: boolean = false
  private snackbarText: string = ''
  private fetchInProgress: boolean = false
  private eventToken: EventToken | null = null
  private showQr: boolean = false


  async fetchEventToken(): Promise<void> {

    let eventId: string = this.loggableEvent.id

    const payload: EventTokenPayload = {id: eventId}

    try {
      this.fetchInProgress = true
      this.eventToken = await this.fetchToken(payload)
      this.showQr = true
    } catch (e) {
      this.showSnackbar = true
      this.snackbarText = this.$t('errors.fetchDataError').toString()
    } finally {
      this.fetchInProgress = false
    }
  }

  get qrData(): string {
    return JSON.stringify(this.eventToken)
  }

  onCloseButtonClick() {
    this.$emit("closeDialog")
  }

  mounted(): void {
    this.fetchEventToken()
  }

}
