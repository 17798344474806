
























import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import * as moment from 'moment'
import { CURRENT_UI_LANGUAGE, } from 'cp/src/store/getter-types'
import { Getter } from 'vuex-class'
import Language from 'cp/src/model/Language'

@Component({
  components: {

  }
})
export default class MonthPicker extends Vue {
  $refs!: {
    dialog: Vue & { save: (date: string) => void }
  }

  private date: string = ""
  private modal: boolean = false

  // @ts-ignore
  @Getter(CURRENT_UI_LANGUAGE) currentUiLanguage: Language
  @Prop({ type: Boolean, default: true }) readonly showArrowButtons!: boolean
  @Prop({ type: String }) readonly selectedMonth!: string | null

  get getFormattedDate (): string {
    // @ts-ignore
    return moment(this.date, 'YYYY-MM').locale(this.currentUiLanguage.code).format('MMM YYYY')
  }

  onSaveClick () {
    this.$refs.dialog.save(this.date)
    this.$emit('onMonthChange', this.date)
  }

  onMonthBeforeClick()  {
    // @ts-ignore
    this.date = moment(this.date, 'YYYY-MM').startOf('month').subtract(1, 'months').format('YYYY-MM')
    this.$emit('onMonthChange', this.date)
  }

  onNextMonthClick() {
    // @ts-ignore
    this.date = moment(this.date, 'YYYY-MM').startOf('month').add(1, 'months').format('YYYY-MM')
    this.$emit('onMonthChange', this.date)
  }

  mounted (): void {
    // @ts-ignore
    this.date = this.selectedMonth ? this.selectedMonth :  moment().format('YYYY-MM')
  }

}
