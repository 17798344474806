import { DateItem, LoggedItem } from '@/types/response'
import moment from "moment"
import store from "app/src/store"
import { CURRENT_UI_LANGUAGE } from '@/store/getter-types'



export default class MonthStatisticDateItem {
  private constructor (
    readonly id: string,
    readonly personId: string,
    readonly firstName: string,
    readonly familyName: string,
    readonly totalWorkedTime: string,
    readonly hasConflicts: boolean,
    readonly date: string,
    readonly arrivalAt: string,
    readonly departureAt: string,
    readonly loggedItems: LoggedItem[]
  ) {}

  get fullName (): string {
    return `${this.firstName} ${this.familyName}`
  }

  get dayWithoutYear (): string {
    return moment(this.date, 'YYYY-MM-DD').format('DD.MM.')
  }

  get dayName (): string {
    return moment(this.date, 'YYYY-MM-DD').locale(store.getters[CURRENT_UI_LANGUAGE].code).format('ddd')
  }

  get formattedArrivalAt (): string {
    return moment(this.arrivalAt).format('H:mm')
  }

  get formattedDepartureAt (): string {
    return moment(this.departureAt).format('H:mm')
  }

  get formattedDate (): string {
    return moment(this.date).format('DD.MM.YYYY')
  }

  public static createFromResponse (response: DateItem[]): MonthStatisticDateItem[] {
    return response.map((item: DateItem) => {
      let minutes: number = moment.duration(parseInt(item.totalWorkedTime)*1000).minutes()
      let hours: number = Math.trunc(moment.duration(parseInt(item.totalWorkedTime)*1000).asHours())
      return new MonthStatisticDateItem(
        item.id,
        item.person.id,
        item.person.firstName,
        item.person.familyName,
        minutes ? `${hours}h ${minutes}m` : `${hours}h`,
        item.hasConflicts,
        item.date,
        item.arrivalAt,
        item.departureAt,
        item.loggedItems
      )
    })
  }

  public static createFromResponseItem (response: DateItem): MonthStatisticDateItem {
    let minutes: number = moment.duration(parseInt(response.totalWorkedTime)*1000).minutes()
    let hours: number = Math.trunc(moment.duration(parseInt(response.totalWorkedTime)*1000).asHours())
    return new MonthStatisticDateItem(
      response.id,
      response.person.id,
      response.person.firstName,
      response.person.familyName,
      minutes ? `${hours}h ${minutes}m` : `${hours}h`,
      response.hasConflicts,
      response.date,
      response.arrivalAt,
      response.departureAt,
      response.loggedItems
    )
  }
}



