import Vue from 'vue'
import Router from 'vue-router'
import { isUserLoggedInGuard, loginPageGuard, rootPageGuard } from '@/router/guards'
import LoginPage from '@/pages/LoginPage.vue'
import DashboardPage from '@/pages/DashboardPage.vue'
import CheckPage from '@/pages/CheckPage.vue'
import OverviewOfPersonPage from '@/pages/OverviewOfPersonPage.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      path: '/',
      name: 'Root',
      beforeEnter: rootPageGuard
    },
    {
      path: '/login',
      name: 'Login',
      beforeEnter: loginPageGuard,
      component: LoginPage
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      beforeEnter: isUserLoggedInGuard,
      component: DashboardPage
    },
    {
      path: '/check',
      name: 'Check',
      beforeEnter: isUserLoggedInGuard,
      component: CheckPage
    },
    {
      path: '/overview-of-person',
      name: 'OverviewOfPersonPage',
      beforeEnter: isUserLoggedInGuard,
      component: OverviewOfPersonPage
    },
    {
      path: '*',
      beforeEnter: isUserLoggedInGuard,
      redirect: '/dashboard'
    },
  ]
})
