//<editor-fold desc="Global actions">
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER'
export const SET_DRAWER_STATE = 'SET_DRAWER_STATE'
export const SWITCH_APPLICATION_LANGUAGE = 'SWITCH_APPLICATION_LANGUAGE'
export const INIT_STORE = 'INIT_STORE'
export const FETCH_PEOPLE = 'FETCH_PEOPLE'
export const CREATE_NEW_PERSON = 'CREATE_NEW_PERSON'
export const UPDATE_PERSON = 'UPDATE_PERSON'
export const FETCH_ROLES = 'FETCH_ROLES'
export const FETCH_DEVICES = 'FETCH_DEVICES'
export const CREATE_NEW_DEVICE = 'CREATE_NEW_DEVICE'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const DELETE_DEVICE = 'DELETE_DEVICE'
export const REGISTER_DEVICE = 'REGISTER_DEVICE'
//</editor-fold>

//<editor-fold desc="Auth module actions">
export const AUTH_INIT_MODULE = 'AUTH_INIT_MODULE'
export const AUTH_LOG_USER_IN = 'AUTH_LOG_USER_IN'
export const AUTH_LOG_USER_OUT = 'AUTH_LOG_USER_OUT'
export const AUTH_LOG_USER_IN_WITH_TOKEN = 'AUTH_LOG_USER_IN_WITH_TOKEN'
export const SET_INVALID_TOKEN = 'SET_INVALID_TOKEN'
//</editor-fold>

export const FETCH_EVENTS = 'FETCH_EVENTS'
export const CREATE_NEW_EVENT = 'CREATE_NEW_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const FETCH_KIND_LIST = 'FETCH_KIND_LIST'
export const FETCH_MONTH_STATISTICS = 'FETCH_MONTH_STATISTICS'
export const FETCH_MONTH_STATISTIC_BY_ID= 'FETCH_MONTH_STATISTIC_BY_ID'
export const FETCH_DATE_STATISTIC_BY_ID= 'FETCH_DATE_STATISTIC_BY_ID'

