




































import Vue from 'vue'
import {Component, Watch} from 'vue-property-decorator'
import {TranslateResult} from 'vue-i18n'
import {IS_USER_LOGGED_IN} from '@/store/getter-types'
import {AUTH_LOG_USER_IN, SET_INVALID_TOKEN} from '@/store/action-types'
import {UserCredentialsPayload} from '@/types/payload'
import LanguageSelectionButton from 'shared/components/LanguageSelectionButton.vue'
import i18n from '@/plugins/i18n'
import {Action, Getter} from 'vuex-class'
import {IS_INVALID_TOKEN} from 'app/src/store/getter-types'

function valueIsRequired(v: string): boolean | TranslateResult {
  return !!v || i18n.t('loginPage.valueIsRequiredMessage')
}

function emailValidationRules(value: string): boolean | TranslateResult {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(value) || i18n.t('loginPage.emailValidationMessage')
}

@Component({
  components: {
    LanguageSelectionButton
  }
})
export default class LoginPage extends Vue {
  $refs!: {
    form: Vue & { validate(): boolean }
  }

  @Getter(IS_USER_LOGGED_IN) isUserLoggedIn!: boolean
  @Getter(IS_INVALID_TOKEN) isInvalidToken!: boolean

  @Action(AUTH_LOG_USER_IN) performLogin!: { (payload: UserCredentialsPayload): Promise<void> }
  @Action(SET_INVALID_TOKEN) setInvalidToken!: { (payload: boolean): void }

  private username: string | null = null
  private password: string | null = null
  private showPassword: boolean = false
  private formIsValid: boolean = false
  private showErrorSnackbar: boolean = false
  private errorMessage: string | null = null
  private loginInProgress: boolean = false

  private usernameValidationRules = [
    valueIsRequired,
    emailValidationRules
  ]

  private passwordValidationRules = [
    valueIsRequired
  ]

  get passwordFieldType(): string {
    return this.showPassword ? 'text' : 'password'
  }

  get passwordFieldIcon(): string {
    return this.showPassword ? 'fa-eye-slash' : 'fa-eye'
  }

  @Watch('isUserLoggedIn')
  onIsUserLoggedInChange(newVal: boolean): void {
    if (newVal) {
      this.$router.push({name: 'Dashboard'})
    }
  }

  onToggleShowPasswordClick(): void {
    this.showPassword = !this.showPassword
  }

  onFormSubmit(): void {
    if (this.$refs.form.validate()) {
      const payload: UserCredentialsPayload = {
        username: this.username!,
        password: this.password!,
      }

      this.loginInProgress = true
      this.setInvalidToken(false)

      this.performLogin(payload)
          .catch(() => {
            this.errorMessage = this.$t('errors.invalidCredentials').toString()
            this.showErrorSnackbar = true
            this.loginInProgress = false
          })
    }
  }
}

