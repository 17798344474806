




import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import QrcodeVue from 'qrcode.vue'

@Component({
  components: {
    QrcodeVue
  }
})
export default class UserQR extends Vue {
  @Prop({ type: String, default: ''}) readonly qrData!: string
}

