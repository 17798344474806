import Vue from 'vue'
import router from './router'
import store from './store'
import './plugins/axios'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import './registerServiceWorker'
import { AUTH_LOG_USER_IN_WITH_TOKEN, INIT_STORE, SWITCH_APPLICATION_LANGUAGE } from '@/store/action-types'
import { AUTH_TOKEN, CURRENT_UI_LANGUAGE } from '@/store/getter-types'
import { isTokenExpiration } from '../../shared/services/util'

Vue.config.productionTip = false;

(async () => {
  try {
    await store.dispatch(INIT_STORE)

    const token = store.getters[AUTH_TOKEN]

    if (token) {

      let isTokenExpired: boolean = isTokenExpiration(token)

      if(!isTokenExpired) {
        await store.dispatch(AUTH_LOG_USER_IN_WITH_TOKEN, token)
      }
    }
  } finally {
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App),

      created () {
        store.dispatch(SWITCH_APPLICATION_LANGUAGE, store.getters[CURRENT_UI_LANGUAGE])
      }
    }).$mount('#app')
  }
})()
