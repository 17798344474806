











import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import AppToolbar from '@/components/AppToolbar.vue'
import AppDrawer from '@/components/AppDrawer.vue'
import { IS_USER_LOGGED_IN } from '@/store/getter-types'
import { Getter } from 'vuex-class'

@Component({
  components: {
    AppToolbar,
    AppDrawer
  }
})
export default class App extends Vue {
  @Getter(IS_USER_LOGGED_IN) isUserLoggedIn!: boolean

  get showAppToolbar (): boolean {
    return this.isUserLoggedIn
  }

  get showAppDrawer (): boolean {
    return this.isUserLoggedIn
  }
}
