import { AuthState } from '@/types/state'
import * as actionTypes from '@/store/action-types'
import * as mutationTypes from '@/store/mutation-types'
import { UserCredentialsPayload } from '@/types/payload'
import CurrentUser from '../../../../shared/model/CurrentUser'
import * as getterTypes from '@/store/getter-types'
import axios from '@/plugins/axios'
import { TOKEN_FIELD } from '@/constants'
import { AxiosResponse } from 'axios'
import { LoginResponse } from '../../../../shared/types/response'
import { AxiosHeaders } from '@/types'

const state: AuthState = {
  currentUser: undefined,
  token: undefined,
  isInvalidToken: false
}

const getters = {
  [getterTypes.IS_USER_LOGGED_IN] (state: AuthState): boolean {
    return !!state.currentUser
    //return true // FIXME - hack na ulahcenie prace
  },

  [getterTypes.CURRENT_USER] (state: AuthState): CurrentUser | undefined {
    return state.currentUser
  },

  [getterTypes.AUTH_TOKEN] (state: AuthState): string | undefined {
    return state.token
  },

  [getterTypes.IS_INVALID_TOKEN] (state: AuthState): boolean {
    return state.isInvalidToken
  }
}

const mutations = {
  [mutationTypes.AUTH_SET_CURRENT_USER] (state: AuthState, payload: CurrentUser | undefined): void {
    state.currentUser = payload
  },

  [mutationTypes.AUTH_SET_TOKEN] (state: AuthState, payload: string): void {
    state.token = payload
    localStorage.setItem(TOKEN_FIELD, payload)
  },

  [mutationTypes.SET_INVALID_TOKEN_MUTATION] (state: AuthState, payload: boolean): void {
    state.isInvalidToken = payload
  }
}

const actions = {
  async [actionTypes.AUTH_INIT_MODULE] ({ commit }: { commit: Function }): Promise<void> {
    const authToken = localStorage.getItem(TOKEN_FIELD)

    if (authToken) {
      commit(mutationTypes.AUTH_SET_TOKEN, authToken)
    }
  },

  async [actionTypes.AUTH_LOG_USER_IN] ({ commit }: { commit: Function }, payload: UserCredentialsPayload): Promise<CurrentUser> {

    const loginResponse: LoginResponse = (await axios.post('/login', payload)).data
    commit(mutationTypes.AUTH_SET_TOKEN, loginResponse.token)

    let headers: AxiosHeaders = {
      Authorization: `Bearer ${loginResponse.token}`
    }

    const accountResponse: AxiosResponse = await  axios.get('/account', {headers: headers})
    const user: CurrentUser = CurrentUser.createFromResponse(accountResponse.data)
    commit(mutationTypes.AUTH_SET_CURRENT_USER, user)

    return user
  },

  async [actionTypes.AUTH_LOG_USER_IN_WITH_TOKEN] ({ commit }: { commit: Function }, payload: string): Promise<CurrentUser> {
    let userResponse: AxiosResponse = await axios.get('/account', {headers: {'Accept': 'application/json', 'Authorization': `bearer ${payload}`}});

    const user: CurrentUser = CurrentUser.createFromResponse(userResponse.data)

    commit(mutationTypes.AUTH_SET_CURRENT_USER, user)

    return user
  },

  async [actionTypes.AUTH_LOG_USER_OUT] ({ commit }: { commit: Function }): Promise<void> {
    commit(mutationTypes.AUTH_SET_CURRENT_USER, undefined)
    localStorage.removeItem(TOKEN_FIELD)
  },

  [actionTypes.SET_INVALID_TOKEN]({commit}: { commit: Function }, payload: boolean): void {
    commit(mutationTypes.SET_INVALID_TOKEN_MUTATION, payload)
  },

}

const authModule = {
  state,
  getters,
  mutations,
  actions
}

export default authModule
