




































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import MonthPicker from '../../../shared/components/MonthPicker.vue'
import {Action} from 'vuex-class'
import {FETCH_DATE_STATISTIC_BY_ID} from '@/store/action-types'
import MonthStatisticDateItem from '../model/MonthStatisticDateItem'
import moment from 'moment'
import {DateStatisticDetailPayload} from "cp/src/types/payload";

@Component({
  components: {
    MonthPicker
  }
})

export default class PersonDetailDialog extends Vue {

  @Action(FETCH_DATE_STATISTIC_BY_ID) fetchDateStatisticById!: { (payload: DateStatisticDetailPayload): Promise<MonthStatisticDateItem> }

  @Prop({type: Boolean, default: false}) readonly show!: boolean
  @Prop({type: Object}) selectedDetail!: MonthStatisticDateItem

  private fetchInProgress: boolean = false
  private dateStatisticsData: MonthStatisticDateItem | null = null

  private errorMessage: string = ''
  snackbar: boolean = false

  onCloseDetailDialog(): void {
    this.$emit('close')
  }

  formattedLoggedItemDate(date: string): string {
    return moment(date).format('H:mm')
  }

  async fetchDateStatistic(): Promise<void> {
    const payload: DateStatisticDetailPayload = {
      id: this.selectedDetail.personId,
      date: this.selectedDetail.date
    }
    try {
      this.fetchInProgress = true
      this.dateStatisticsData = await this.fetchDateStatisticById(payload)
    } catch (e) {
      this.errorMessage = this.$t('errors.fetchDataError').toString()
      this.snackbar = true
    } finally {
      this.fetchInProgress = false
    }
  }

  mounted(): void {
    this.fetchDateStatistic()
  }

}
